import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

const Container = styled.div``;

const Blocks = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  flex-wrap: wrap;
`;

const Line = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  vertical-align: middle;
  line-height: 1.5rem;
  margin: 0.5rem 0;
`;

const CHANNEL_QUERY = gql`
  query ThankYouIndexQuery {
    channel(id: "thank-you-index-for-the-are-na-annual") {
      connections {
        user {
          name
          href(absolute: true)
        }
        connectable {
          ... on Connectable {
            title
            href(absolute: true)
            user {
              name
              href(absolute: true)
            }
          }
        }
      }
    }
  }
`;

export const ThankYouIndex: React.FC = () => {
  const { loading, error, data } = useQuery(CHANNEL_QUERY);

  if (loading || error || !data) {
    return null;
  }

  const {
    channel: { connections },
  } = data;

  return (
    <Container>
      <Blocks>
        {(connections as Array<any>).map((connection) => {
          return (
            <Line>
              <a href={connection.user.href} target="_blank">
                {connection.user.name}
              </a>{" "}
              is thankful for{" "}
              <a href={connection.connectable.href} target="_blank">
                <span
                  dangerouslySetInnerHTML={{
                    __html: connection.connectable.title,
                  }}
                />
              </a>{" "}
              by{" "}
              <a href={connection.connectable.user.href} target="_blank">
                {connection.connectable.user.name}
              </a>
              .
            </Line>
          );
        })}
      </Blocks>
    </Container>
  );
};
