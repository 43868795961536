import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import styled from "styled-components";

import client from "apollo/index";
import { ThankYouIndex } from "components/Index";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;

const FixedLogo = styled.img.attrs({
  src: "diagram.png",
})`
  position: fixed;
  right: 2em;
  width: 15vw;
  top: 2em;

  @media only screen and (max-width: 900px) {
    position: static;
    width: 30%;
  }
`;

const Description = styled.div`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  line-height: 1.5rem;
  width: 50vw;

  @media only screen and (max-width: 900px) {
    position: static;
    width: auto;
  }
`;

const Main: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Container>
        <FixedLogo />
        <Description>
          <p>
            For this year’s Are.na Annual, we want to put together an index of
            the channels we’re thankful for. This website displays the index as
            it is added to and will be printed in this year's annual.
          </p>{" "}
          <p>
            If you’d like to contribute, please connect a channel that you're
            glad someone else made to{" "}
            <a
              href="https://www.are.na/are-na-team/thank-you-index-for-the-are-na-annual"
              target="_blank"
            >
              Thank You Index for the Are.na Annual
            </a>
            . Please add your submission before December 3rd 2020.
          </p>
        </Description>
        <ThankYouIndex />
      </Container>
    </ApolloProvider>
  );
};

export default Main;
